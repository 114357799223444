import React from "react";
import {Box, Image} from "@chakra-ui/react";

const getPropsFromTitle = (title) => {
  const props = { 
    w: "272px",
    ml: "auto",
    mr: "auto",
  }

  if (!title) {
    return props;
  }

  const words = title.toLowerCase().split(" ");
  words.forEach(w => {
    if (w==="small") {
      props["w"] = "120px";
    }
    if (w==="medium") {
      props["w"] = "272px";
    }
    if (w==="large") {
      props["w"] = {base: "full", md: "480px"};
    }
    if (w==="full") {
      props["w"] = "full";
    }

    if (w==="right") {
      props["float"] = "right";
      props["ml"] = "0.5em";
    }
  });
  return props;
}

// Use title to overwrite size.
// Expects this structure:
//   <div class="gatsby-resp-image-wrapper">
//     <span class="gatsby-resp-image-background-image" ... />
//     <img src="..." alt="..." title="..." class="gatsby-resp-image-image" ... />
//   </div>
// Grab title from img, interpret it, apply to bounding box.
// Purge title form img element.
export function MDXResponsiveImage(props) {

  const imageIdx = props.children.findIndex(c=>c.props && c.props.className==="gatsby-resp-image-image");
  if (imageIdx === -1) {
    return <span {...props} />;
  }

  const image = props.children[imageIdx];
  const boxProps = getPropsFromTitle(image.props.title);

  const newProps = {...props, children: props.children.slice(0)};
  newProps.children[imageIdx] = <Image {...{...image.props, title: ""}} />; // purge title

  return <Box my="1.5rem" {...boxProps}><span {...props} /></Box>
}

// For images which are not responsive - simply add sizing.
// There should only be one or two of these - gifs and SVGs.
export function MDXSimpleImage(props) {
  // Sadly this ends up being called with on output from MDXResponsiveImage, so skip those.
  if (props && props.className && props.className.includes("gatsby-resp-image-image")) {
    return <img {...props} />;
  }
  const boxProps = getPropsFromTitle(props.title);
  return <Image my="1.5rem" {...boxProps} className="simple" {...props} />;
}
