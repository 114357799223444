// Do magic things with spans!
// - detect image wrapper and pass over to MDXImage
import React from "react";
import { MDXResponsiveImage } from "./MDXImage";

export default function MDXSpanMagic(props) {
  if (props.className && props.className === "gatsby-resp-image-wrapper") {
    return <MDXResponsiveImage {...props} />;
  }
  return <span {...props} />
}
