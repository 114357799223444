import React from "react";
import { graphql } from "gatsby"; // We need to explicitly import GraphQL here.
import { getImage } from "gatsby-plugin-image";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx"; //Since we want to render MDX, we need this plugin.

import {
  Box,
  Divider,
  Heading,
  Text,
  HStack,
  ListItem,
  OrderedList,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";

import Footer from "../components/Footer";
import Framed from "../components/Framed";
import GuideFooter from "../components/guide_footer.md";
import Header from "../components/Header";
import {Highlight} from "../components/Highlight";
import Link from "../components/Link";
import { MDXSimpleImage } from "../components/MDXImage";
import MDXSpanMagic from "../components/MDXSpanMagic";
import { SpecificHeaders } from "../components/SocialHeaders";
import TableOfContents from "../components/TableOfContents";
import Tags from "../components/Tags";

export default function PostPage(props) {
  const { slug, frontmatter, body, timeToRead, tableOfContents } =
    props.data.mdx;

  const bannerImage = getImage(frontmatter.image);

  const adjustedReadTime = Math.round(timeToRead * 1.3);

  const chakraUiComponents = {
    h1: (props) => <Heading as="h2" {...props} />,
    h2: (props) => <Heading as="h3" {...props} />,
    h3: (props) => <Heading as="h4" {...props} />,
    h4: (props) => <Heading as="h5" {...props} />,
    h5: (props) => <Heading as="h6" {...props} />,
    p: Text,
    a: Link,
    li: ListItem,
    ol: OrderedList,
    ul: UnorderedList,
    toc: (props) => (
      <TableOfContents items={tableOfContents.items} {...props} />
    ),
    GuideFooter,
    hr: (props) => (
      <Divider
        w="75%"
        my="1.5em"
        mx="auto"
        borderColor="brand.lightBlue"
        // borderBottomWidth="2px"
        // opacity="1"
      />
    ),
    span: MDXSpanMagic,
    img: MDXSimpleImage,
    HStack: (props) => (
      <HStack
        {...{
          justify: "space-evenly",
          wrap: "wrap",
          rowGap: "0.5rem",
          ...props,
        }}
      />
    ),
    captioned: ({ children, caption }) => (
      <figure>
        {children}
        <figcaption>{caption}</figcaption>
      </figure>
    ),
    iframe: (props) => <iframe {...props}></iframe>,
    highlight: Highlight,
    More: () => <></>, // intended to be the jump break, but not relevant.  keeps giving errors if not consumed.
  };

  const socialImage = getImage(
    frontmatter.socialImage || frontmatter.socialFallback
  );

  return (
    <>
      <Header
        imageHook={() => ({
          image: bannerImage,
          credit: frontmatter.imageCreditText,
          url: frontmatter.imageCreditURL,
        })}
        justify="center"
      >
        <SpecificHeaders
          path={slug}
          title={frontmatter.title}
          description={frontmatter.description}
          image={socialImage.images.fallback.src}
          isArticle={true}
          publishedTime={frontmatter.date}
          updatedTime={frontmatter.updated}
          tags={frontmatter.tags}
        />
        <VStack>
          <Heading as="h1">{frontmatter.title}</Heading>
          {frontmatter.tags && (
            <Tags mode="header" pt="1.2rem" pb="1.8rem">
              {frontmatter.tags}
            </Tags>
          )}
          <Text align="center" fontSize="2xl" lineHeight={1.4}>
            {frontmatter.description}
          </Text>
          {timeToRead && (
            <Text pt="1rem">
              <em>Estimated read time: {adjustedReadTime} minutes</em>
            </Text>
          )}
        </VStack>
      </Header>
      <Framed as="main">
        <Box
          pt={{ base: "1rem", lg: "90px" }}
          pb="140px"
          px={{ base: 0, lg: "200px", "2xl": "276px" }}
          textStyle="mdx"
        >
          <MDXProvider components={chakraUiComponents}>
            <MDXRenderer>{body}</MDXRenderer>
          </MDXProvider>
        </Box>
      </Framed>
      <Footer />
    </>
  );
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      slug
      frontmatter {
        date
        title
        tags
        description
        image {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        imageCreditText
        imageCreditURL
        socialFallback: image {
          childImageSharp {
            gatsbyImageData(
              layout: FIXED
              width: 1200
              height: 630
              outputPixelDensities: [1]
            )
          }
        }
        socialImage {
          childImageSharp {
            gatsbyImageData(
              layout: FIXED
              width: 1200
              height: 630
              outputPixelDensities: [1]
            )
          }
        }
      }
      body
      timeToRead
      tableOfContents
    }
  }
`;
