import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <hr></hr>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "850px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.33802816901409%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAACUUlEQVQoz2OwjYlkgGJG25hIJpvoCGbbmEhmKJsBhEHyRIOAnCzG////M4LYCi4ODOI2FgziNpYM/KaGDE4JsYweqckMfQvmgQ0WUtSGYi0GYUVtOIbzFbRBBmYy/P//H2Qel7qnW4iWj+cSDS/3qSLGxnZ+RVkMUQ3ljDySKowwjchYWFEbJM6IbAGDY3yM1aTFi65NW7b0dkxZyf+C9tb/nXNm/a/o7f5lHBxgYxwSyMDAKgLTwAjBWiiuElJAuJYhrDBfLbe1afXuY0f/L1i/7l9Fb/fvvNbm78u2bP7vlZ6yfcX2rQyiyrpWIso6nAhvavEJK2rxCCtqKworaqsLQwxkE1bUlmBQdnMGeVejcerk/7WTJvzzy0r/VzOx/+/0Fcv+28VEXeNkkWIQUtAqFVbUbhVS1I4XVtRuFFbUniusqO0hrKi9UFhRu1NYUbtMSEF7mpCiVgA4Ylh0NATCCvNv3nn48P/JSxd/nb927ceSTRv/OyXGzWVgYBAQUdKpAmkSVtSeCDWwXFhR20ZYUTtSSEGrQFhBq0tYUbtXSEHLkUHU2pzBOiqcxToq/FLTtCn/q/p7/4cV5v+Pryi75JWeIpPUWsPgHBfLBAsveMwqQCJDCByGWmAMCltQ0gBhNpvoiCAlV8cIBWeHWHVPtzBQrLulJDIEl+YzFk7ugkSIAtRQkGEQw0GGQiIJzAcbGAc21CoyjEHS1opB2NKUQc7JDhwUjvExTJ6ZqQzy+uYo6U4Ymh6FsWB4TrCJjmCC5hBmaG5htAGJQ3MSsQAA3ILBjQNNRCMAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Just Kai logo",
        "title": "Just Kai logo",
        "src": "/static/489fc697d513fe247f0c01e81a6077b4/ae694/primary.png",
        "srcSet": ["/static/489fc697d513fe247f0c01e81a6077b4/3cb16/primary.png 213w", "/static/489fc697d513fe247f0c01e81a6077b4/2fbbf/primary.png 425w", "/static/489fc697d513fe247f0c01e81a6077b4/ae694/primary.png 850w", "/static/489fc697d513fe247f0c01e81a6077b4/3f20e/primary.png 1275w", "/static/489fc697d513fe247f0c01e81a6077b4/dcb79/primary.png 1700w", "/static/489fc697d513fe247f0c01e81a6077b4/e7072/primary.png 3841w"],
        "sizes": "(max-width: 850px) 100vw, 850px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <p><strong parentName="p">{`Our bottom line is that fish, cocoa and sugar should be free of child and slave labour right back to the original boat or farm.`}</strong></p>
    <p><strong parentName="p">{`Within this, where possible we also:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`identify goods produced in low-income countries (as buying those increases employment opportunities there);`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`identify which goods are produced in the best labour conditions available (as supporting those brands will increase the pool of good jobs people have to choose from).`}</strong></li>
    </ul>
    <p><strong parentName="p">{`Find out more`}</strong>{` `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/blog/2017-03-05-shopping-for-human-rights/"
        }}>{`about our principles`}</a></strong>{` `}<strong parentName="p">{`and`}</strong>{` `}<strong parentName="p">{`check out our`}</strong>{` `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/guides/"
        }}>{`other guides`}</a>{`.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      